// ForgotPasswordPage.js
import React from 'react';
import ForgotPassword from '../pages/ForgotPassword';

const ForgotPasswordPage = () => {
  return (
    <div className="your-custom-styling">
      {/* Plaats hier je aangepaste opmaak voor de pagina */}
      <ForgotPassword />
    </div>
  );
};

export default ForgotPasswordPage;
